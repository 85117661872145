<template>
  <material-chart-card
    id="DashboardCharts"
    :color="charts[0].color"
    :data="charts[0].data"
    :options="charts[0].options"
    :responsive-options="charts[0].responsiveOptions"
    :title="charts[0].title"
    :type="charts[0].type"
  >
    <template #subtitle>
      <div class="font-weight-light text--secondary">
        <div v-html="charts[0].subtitle" />
      </div>
    </template>

    <template #actions>
      <v-icon class="mr-1" small> mdi-clock-outline </v-icon>

      <span
        class="text-caption grey--text font-weight-light"
        v-text="charts[0].time"
      />
    </template>
  </material-chart-card>
</template>
<script>
import Vue from 'vue';
import chartist_tooltip from 'chartist-plugin-tooltip';
const lineSmooth = Vue.chartist.Interpolation.cardinal({
  tension: 0,
});

export default {
  mounted() {},
  name: 'GraficaDeLineas',
  components: {},
  data: () => ({
    charts: [
      {
        type: 'Line',
        color: 'blue-grey darken-2',
        title: 'Daily Sales',
        subtitle:
          '<i class="mdi mdi-arrow-up green--text"></i><span class="green--text">55%</span>&nbsp;increase in today\'s sales',
        time: 'updated 4 minutes ago',
        data: {
          labels: [
            '12am',
            '6am',
            '8am',
            '10am',
            '12pm',
            '14pm',
            '16pm',
            '18pm',
            '20pm',
            '22pm',
            '23pm',
          ],
          series: [
            [
              { meta: 'pelotas', value: 230 },
              750,
              450,
              300,
              280,
              240,
              200,
              190,
            ],
            [3, 25, 12, 11, 8, 14, 4],
          ],
        },
        options: {
          plugins: [
            chartist_tooltip({
              appendToBody: true,
              pointClass: 'my-cool-point',
            }),
          ],
          lineSmooth,
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 1540px)',
            {
              horizontalBars: false,
              axisY: {
                offset: 58,
              },
              axisX: {
                labelOffset: {
                  x: -10,
                  y: 0,
                },
              },
              //la distancia "disponible" que hay entre barras
              seriesBarDistance: 15,
            },
          ],
        ],
      },
    ],
  }),
};
</script>
